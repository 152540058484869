import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import AsyncAction from './AsyncAction';
import { follow, unfollow } from '../../store/userAccount';
import { Wishlist16Svg, Heart20Svg, RedBliningHeart } from '../../svg';

function CheckButtonCondition(props) {
  const {
    user, follow, unfollow, product,
  } = props;
  const history = useHistory();

  const checkCondition = () => {
    if (user) {
      console.log('product=>', product);
      if (product && product.isFollowing) {
        return unfollow(product.adId);
      }
      return follow(product.adId);
    }
    toast.info('İlanı Takip Etmek İçin Giriş Yapınız!');
    history.push('/hesap/cikis');

    return new Promise((resolve) => {
      resolve();
    });
  };
  const buttonCondition = (
    <AsyncAction
      action={() => checkCondition()}
      render={({ run, loading }) => (
        <button
          type="button"
          onClick={run}
          className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
            'btn-loading': loading,
          })}
        >
          {product.isFollowing
            ? <RedBliningHeart className="heart_blink" />
            : <Heart20Svg />}
        </button>
      )}
    />

  );
  return (
    <div>
      {buttonCondition}
    </div>
  );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  follow,
  unfollow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckButtonCondition);
