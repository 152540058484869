import { toast } from 'react-toastify';

import axios from 'axios';
import { API_BASE } from '../../env';
import { history, authHeader, authHeaderWithSecret } from '../../helpers';
export const CART_UPDATE = 'CART_UPDATE';
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export const CART_UPDATE_FAIL = 'CART_UPDATE_FAIL';
export const CART_GET = 'CART_GET';
export const CART_GET_SUCCESS = 'CART_GET_SUCCESS';
export const CART_GET_FAIL = 'CART_GET_FAIL';


export function cartUpdate(cartId, adId, amount) {
    let cartModel = {
        cartId,
        adId,
        amount
    }
    let user = JSON.parse(localStorage.getItem('user'));
    if (user === null) {
        history.push('/hesap/cikis')
        toast.warning("Ürünü sepete ekleyebilmek için lütfen giriş yapın!")
        return () => (
            new Promise((resolve) => {
                resolve();
            })
        );
    }
    return (dispatch) => {
        dispatch(postCartUpdate(true));
        let asyncAction = axios.post(`${API_BASE}/cart/update`,
            cartModel,
            { headers: authHeaderWithSecret() });
        asyncAction.then((response) => {
            dispatch(postCartUpdateSuccess(response.data));
            if (response.data.success.warning) {
                toast.warning(response.data.success.warning);
            } else {
                toast.success(`Sepet güncellendi`);
            }
        }).catch((err) => {
            dispatch(postCartUpdateFail(false));
            if (err.response.data.failed) {
                toast.error(err.response.data.failed);
            }
        });
        return asyncAction;
    };
}
export const postCartUpdate = (bool) =>
({
    type: CART_UPDATE,
    payload: bool,
});

export const postCartUpdateSuccess = (data) =>
({
    type: CART_UPDATE_SUCCESS,
    payload: data.success,
});

export const postCartUpdateFail = (error) =>
({
    type: CART_UPDATE_FAIL,
    payload: error,
});

export function cartGet(cartId, isOrder) {
    let cartModel = {
        cartId,
        isOrder
    }
    return (dispatch) => {
        dispatch(postCartGet(true));
        let asyncAction = axios.post(`${API_BASE}/cart/get`,
            cartModel,
            { headers: authHeaderWithSecret() })
            .then((response) => {
                dispatch(postCartGetSuccess(response.data));
            }).catch((err) => {
                dispatch(postCartGetFail(err));
            });
        return asyncAction;
    };
}
export const postCartGet = (bool) =>
({
    type: CART_GET,
    payload: bool,
});

export const postCartGetSuccess = (data) =>
({
    type: CART_GET_SUCCESS,
    payload: data.success,
});

export const postCartGetFail = (error) =>
({
    type: CART_GET_FAIL,
    payload: error,
});
