import React, { Fragment, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// third-party
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
  Switch,
  Router,
} from 'react-router-dom';
// pages
import HomePage from './HomePage';
import { mergeArrays } from '../../helpers/merger';

// import HomePageTwo from './home/HomePageTwo';

export default function HomePageWrapper() {
  const [mergedList, setMergedList] = useState([]);
  const { adList, instantAdsInfo } = useSelector((state) => ({
    adList: state.ad.adList,
    instantAdsInfo: state.ad.instantAdsInfo,
  }));

  useEffect(() => {
    setMergedList(mergeArrays(adList, instantAdsInfo));
  }, [adList, instantAdsInfo]);
  return (
    <div>
      <HomePage
        mergedList={mergedList}
      />
    </div>
  );
}

HomePageWrapper.propTypes = {
  /** current locale */
  locale: PropTypes.string,
};
