import { init } from '@sentry/react';
import {
    CART_UPDATE,
    CART_UPDATE_SUCCESS,
    CART_UPDATE_FAIL,
    CART_GET,
    CART_GET_SUCCESS,
    CART_GET_FAIL,
} from './cartActions';

const initialState = {
    isLoading: false,
    info: {},
    taxes: [],
    items: [],
};
const updateCart = (state, data) => {
    let result = {
        ...state,
        info: { ...data.info },
        taxes: [...data.taxes],
        items: [...data.items],
        isLoading: false,
    };
    return result;
};
function clearCart(state) {
    return state = initialState;
}
export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_GET:
            return {
                ...state,
                isLoading: action.payload,
            };
        case CART_GET_SUCCESS:
            return updateCart(state, action.payload);
        case CART_GET_FAIL:
            return clearCart(state)
        case CART_UPDATE:
            return {
                ...state,
                isLoading: action.payload,
            };
        case CART_UPDATE_SUCCESS:
            return updateCart(state, action.payload);
        case CART_UPDATE_FAIL:
            return  {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
}
