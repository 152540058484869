// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import 'react-phone-input-2/lib/style.css';
import Currency from '../shared/Currency';
import addressInfo from '../../helpers/addressInfo';

import 'moment/locale/tr';

function AccountPageDashboard(props) {
  moment().locale('tr');
  const user = JSON.parse(localStorage.getItem('user'));
  const { orders, address } = props;
  const defaultAddress = address && address[0];

  const orderList = orders && orders.slice(0, 3).map((order) => (
    <tr key={order.uuid}>
      <td>
        <Link
          to={`/hesap/siparis/${order.uuid}`}
        >
          {`#${order.uuid.substring(0, 8)}`}
        </Link>
      </td>
      <td>
        {/* {moment(order.createdDate).format('Do MMMM YYYY HH:mm')} */}
        {order.dateCreated}
      </td>
      <td>{order.status}</td>
      <td>{order.subTotal}</td>
      <td className="text-success">
        {order.totalRefund}
      </td>
    </tr>
  ));

  const addressArea = (address && address[0] && (
  <div className="dashboard__address card address-card address-card--featured">
    <div className="address-card__body">
      {addressInfo(defaultAddress, 'Varsayılan Adres')}
      <div className="address-card__footer">
        <Link className="btn btn-primary btn-sm" to="/hesap/adres">Adres Düzenle</Link>
      </div>
    </div>
  </div>
  ));

  return (
    <div className="dashboard">
      <Helmet>
        <title>{`Hesabım — ${theme.name}`}</title>
      </Helmet>

      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          <div className="profile-card__avatar">
            {(user && user.image) ? <img src={user.image} alt="" /> : <img src="images/avatars/avatar-3.jpg" alt="" />}
          </div>
          <div className="profile-card__name">
            {user.firstName}
            {' '}
            {user.lastName}
          </div>
          <div className="profile-card__email">{user.email}</div>
          <div className="profile-card__edit">
            <Link to="profil" className="btn btn-secondary btn-sm">Profili Düzenle</Link>
          </div>
        </div>
      </div>
      {addressArea}
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  address: state.profile.address,
  adList: state.ad.adList,
});
const mapDispatchToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDashboard);
