export default [
    // {
    //     title: 'Ana Sayfa',
    //     url: '/',
    // },
    // {
    //     title: 'Shop',
    //     url: '/shop/category-grid-3-columns-sidebar',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'Shop Grid',
    //                 url: '/shop/category-grid-3-columns-sidebar',
    //                 submenu: [
    //                     { title: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
    //                     { title: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
    //                     { title: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
    //                 ],
    //             },
    //             // { title: 'Shop List', url: '/kategori' },
    //             { title: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
    //             {
    //                 title: 'Product',
    //                 url: '/shop/product-standard',
    //                 submenu: [
    //                     { title: 'Product', url: '/shop/product-standard' },
    //                     { title: 'Product Alt', url: '/shop/product-columnar' },
    //                     { title: 'Product Sidebar', url: '/shop/product-sidebar' },
    //                 ],
    //             },
    //             { title: 'Cart', url: '/sepet' },
    //             { title: 'Checkout', url: '/shop/checkout' },
    //             { title: 'Order Success', url: '/shop/checkout/success' },
    //             { title: 'Wishlist', url: '/favoriler' },
    //             { title: 'Compare', url: '/shop/compare' },
    //             { title: 'Track Order', url: '/shop/track-order' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Account',
    //     url: '/hesap',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Dashboard', url: '/hesap/bilgiler' },
    //             { title: 'Edit Profile', url: '/hesap/profil' },
    //             { title: 'Order History', url: '/hesap/siparis' },
    //             { title: 'Order Details', url: '/hesap/siparis/5' },
    //             { title: 'Address Book', url: '/hesap/adres' },
    //             { title: 'Edit Address', url: '/hesap/adres/5' },
    //             { title: 'Change Password', url: '/hesap/sifre' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Bloglar',
    //     url: '/blog/category-classic',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Blog Classic', url: '/blog/category-classic' },
    //             { title: 'Blog Grid', url: '/blog/category-grid' },
    //             { title: 'Blog List', url: '/blog/category-list' },
    //             { title: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //             { title: 'Post Page', url: '/blog/post-classic' },
    //             { title: 'Post Without Sidebar', url: '/blog/post-full' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Sayfalar',
    //     url: '/site/hakkımızda',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Hakkımızda', url: '/site/hakkimizda' },
    //             { title: 'İletişim', url: '/site/iletisim' },
    //             { title: 'Contact Us Alt', url: '/site/iletisim-alt' },
    //             { title: '404', url: '/site/not-found' },
    //             { title: 'Şartlar ve Üyelik Koşulları', url: '/site/kosullar' },
    //             { title: 'SSS', url: '/site/sss' },
    //             { title: 'Components', url: '/site/components' },
    //             { title: 'Typography', url: '/site/typography' },
    //         ],
    //     },
    // },
];
