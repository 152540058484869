// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';
import BizlealLogo from '../../png/bizleal-logo.png';
import Departments from './Departments';
import Indicator from './Indicator';
import { Heart20Svg, LogoSmallSvg } from '../../svg';
import { IndicatorAccountDispatch } from './IndicatorAccountDispatch';
import IndicatorCart from './IndicatorCart';

function Header(props) {
  const { layout, favourites } = props;
  let bannerSection;
  const user = JSON.parse(localStorage.getItem('user'));

  if (layout === 'default') {
    bannerSection = (
      <div className="site-header__middle container">
        <div className="site-header__logo">
          <Link to="/"><img src={BizlealLogo} alt="Logo" className="logo-bizleal" /></Link>
        </div>
        <div className="site-header__search">
          <Search context="header" />
        </div>

        <div className="nav-panel__indicators">

          {user && (
          <div className="account_icons_custom">

            <Indicator
              url={user ? '/favoriler' : '/hesap/cikis'}
              value={(favourites && (favourites.length > 0 ? favourites.length : undefined)) || undefined}
              icon={<Heart20Svg />}
            />
            <p>Favorilerim</p>
          </div>
          )}

          <div className="account_icons_custom">
            <IndicatorCart />
            <p>Sepetim</p>
          </div>

          <div className="account_icons_custom">
            <IndicatorAccountDispatch />
            <p>Hesabım</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="site-header">
      <Topbar />
      {bannerSection}
    </div>
  );
}

Header.propTypes = {
  /** one of ['default', 'compact'] (default: 'default') */
  layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
  layout: 'default',
};

const mapStateToProps = (state) => ({
  favourites: state.userAccount.favorites,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
