// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// blocks
import BlockSlideShowDeneme from '../blocks/BlockSlideShowDeneme';
// data stubs

import theme from '../../data/theme';
import CategorySlider from '../blocks/CategorySlider';
import BizlealHotAdsSlider from '../blocks/BizlealHotAdsSlider';
import { Link } from 'react-router-dom';

import PageHeader from '../shared/PageHeader';
// application
import {

    ClockIcon,
    CreditCardIcon,
    BirlikteAl,
    Woman,
    ManAndWoman,
    SafePay, HomePageCard1, HomePageCard2, HomePageCard3, HomePageArrowRight, HomePageArrowDown,
} from '../../svg';

import BizlealAdsSlider from '../blocks/BizlealAdsSlider';

export default function HomePage(props) {
    const { mergedList } = props;
    return (
        <>
            <Helmet>
                <title>{`Ortak Satın Alma Platformu — ${theme.name}`}</title>
            </Helmet>
            <div className="home_layout_grid container block-features--layout--classic">
                <CategorySlider />

                <BlockSlideShowDeneme mergedList={mergedList} />

                <BizlealHotAdsSlider
                    title="Anlık Fırsatlar"
                    layout="grid-1"
                    mergedList={mergedList}
                />

            </div>
            <div className="sticky_top_ads">

                <div className="container my-5">
                    <div className="d-flex justify-content-center">
                        <Link to={'/kategori'}
                            className="btn btn-primary btn-lg w-100"
                            style={{ borderRadius: '10px' }}
                        >
                            İlanlara Göz At</Link>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <h3 className="text-center m-5">Sistem Nasıl Çalışır?</h3>

                <div className="row">
                    <div className="col-12 col-lg-3">
                        <div className="card h-100">
                            <div className="card-body align-self-center">
                                <HomePageCard1 />

                            </div>
                            <div className="card-footer text-center p-3">
                                Ürünler Satış Fiyatından Alınır
                            </div>
                        </div>
                    </div>

                    <div className="col align-self-center justify-content-center">
                        <div className="d-lg-none">
                            <HomePageArrowDown className="w-100 my-3" />
                        </div>
                        <div className="d-none d-lg-block">
                            <HomePageArrowRight className="w-100" />
                        </div>
                    </div>

                    <div className="col-12 col-lg-3">
                        <div className="card h-100">
                            <div className="card-body align-self-center">
                                <HomePageCard2 />

                            </div>
                            <div className="card-footer text-center p-2">
                                Toplam Satış Adedine Göre Fiyat Düşer
                            </div>
                        </div>
                    </div>

                    <div className="col align-self-center justify-content-center">
                        <div className="d-lg-none">
                            <HomePageArrowDown className="w-100 my-3" />
                        </div>
                        <div className="d-none d-lg-block">
                            <HomePageArrowRight className="w-100" />
                        </div>
                    </div>

                    <div className="col-12 col-lg-3">
                        <div className="card h-100">
                            <div className="card-body align-self-center">
                                <HomePageCard3 />
                            </div>
                            <div className="card-footer text-center p-2">
                                İlan Süresi Sonunda Fiyat Farkı Kredi Kartınıza İade Edilir
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className="text-center m-5">Bizleal Sistemi Neden Mantıklı?</h3>

                <div className="home_page_info_wrapper col-lg-12 row align-items-center mb-5 mb-md-1">
                    <Woman className="col-sm-6 order-md-2" />
                    <div className="home_page_info_text col-sm-6 p-sm-2 p-lg-5 text-center text-md-left">
                        <h4>Ne Kadar Satış O Kadar İndirim</h4>
                        <div className="content">
                            <p>
                                Ürün fiyatı toplam satış adedine göre düşer. Ürüne ne kadar talep gelirse, ürün fiyatı o kadar indirime girer.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="home_page_info_wrapper col-lg-12 row align-items-center mb-5 mb-md-1">
                        <BirlikteAl className="col-sm-6" />
                        <div className="home_page_info_text col-sm-6 p-sm-2 p-lg-5 text-center text-md-left">
                            <h4>Sosyal Satın Alma</h4>
                            <div className="content">
                                <p>
                                    İlanları sosyal medyada, whatsapp  ve telegram gibi uygulamalarda paylaşarak ürün fiyatının düşmesine katkı sağlayabilirsiniz.
                                </p>
                            </div>
                        </div>
                    </div>



                    <div className="home_page_info_wrapper col-lg-12 row align-items-center mb-5 mb-md-1">
                        <ManAndWoman className="col-sm-6 order-md-2" />
                        <div className="home_page_info_text col-sm-6 p-sm-2 p-lg-5 text-center text-md-left">
                            <h4>Diğer Alışveriş Sitelerinden Farkımız</h4>
                            <div className="content">
                                <p>
                                    Bizleal'da ürünler doğrudan tedarikçiden, toptan fiyatına satılır. Bireyler birleşerek satın alma gücünü artırmış olur.
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="home_page_info_wrapper col-lg-12 row align-items-center mb-5 mb-md-1">
                        <SafePay className="col-sm-6" />
                        <div className="home_page_info_text col-sm-6 p-sm-2 p-lg-5 text-center text-md-left">
                            <h4>Tamamen Güvenli</h4>
                            <div className="content">
                                <p>
                                    Ürün elinize geçmeden, ödemeniz tedarikçinin hesabına aktarılmaz. Dolayısıyla tamamen güvenli alışveriş  yapmış olursunuz.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="row text-center mb-5">
                    <div className="col-sm-12 col-lg-6">
                        <div className="row mb-2">
                            <div className="col">
                                <ClockIcon className="m-auto" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col m-4">
                                <h4>Ürünlerdeki Sayaç Nedir?</h4>
                                <p>
                                    Bizleal'da ürünler ilanlar şeklinde yayınlanır. İlan, bitiş tarihine kadar yayında kalır. Sayaç, son ilan bitimine kalan süreyi gösterir.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="row mb-2">
                            <div className="col">
                                <CreditCardIcon className="m-auto" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col m-4">
                                <h4>Fiyatlar Neden Düşük?</h4>
                                <p>
                                    Bizleal'da tedarikçiler, ürünlerini, onlarca, yüzlerce müşteriye toptan fiyatına doğrudan satar. Herhangi bir aracı olmadığı için de çok daha indirimli fiyatlara satın alma gerçekleştirmek mümkündür.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <BizlealAdsSlider
                    gridLayout="home_new_ads_list"
                    numOfSlidesToShow={4}
                    list={mergedList}
                    title="Yeni Ürünler"
                />
                <br />
                <BizlealAdsSlider
                    gridLayout="home_favorite_ads_list"
                    numOfSlidesToShow={4}
                    list={mergedList}
                    title="En Çok Satanlar"
                /> */}
            </div>

        </>
    );
}
