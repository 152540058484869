// react
import React, { Fragment, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import AsyncAction from './AsyncAction';
import { follow, unfollow } from '../../store/userAccount';
import { Wishlist16Svg, BlankHeart, RedBliningHeart } from '../../svg';

export default function FollowButton(props) {
  const {
    adId, isFollowing, onFollow, numOfParticipants,
  } = props;

  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();

  const increment = (participants) => {
    if (onFollow) {
      onFollow(++participants);
    }
  };
  const decrement = (participants) => {
    if (onFollow) {
      onFollow(--participants);
    }
  };

  const checkDefaultValue = () => {
    if (user) {
      if (isFollowing === true) {
        return true;
      }
      return false;
    }
    return false;
  };
  const [isClick, setIsClick] = useState(() => checkDefaultValue());

  const participants = numOfParticipants;
  const checkCondition = () => {
    if (user) {
      if (isFollowing === true) {
        setIsClick(true);
        decrement(participants);
        setIsClick(false);
        return dispatch(unfollow(adId));
      }
      setIsClick(true);
      increment(participants);
      return dispatch(follow(adId));
    }
    toast.info('İlanı Takip Etmek İçin Giriş Yapınız!');
    history.push('/hesap/cikis');
    return new Promise((resolve) => {
      resolve();
    });
  };
    // const buttonCondition = (
    //     <Heart isClick={isClick}
    //         onClick={() => checkCondition()}
    //     />

  // )
  const buttonCondition = (
    <AsyncAction
      action={() => checkCondition()}
      render={({ run, loading }) => (
        <button
          type="button"
          onClick={run}
                    // className={classNames('btn', {
                    //     'btn-loading': loading,
                    // })}
          className={classNames('btn-like', {
            'btn-loading': loading,
          })}
        >
          {isFollowing
            ? <RedBliningHeart className="heart_blink" />
            : <RedBliningHeart className="blank" />}
        </button>
      )}
    />

  );
  return (
    <div>
      {buttonCondition}
    </div>
  );
}
