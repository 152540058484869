// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountWrapper from './account/AccountWrapper';
import AccountConfirm from './account/AccountConfirm';
import AccountPageLogout from './account/AccountPageLogout';
import AccountPageForgotPassword from './account/AccountPageForgotPassword';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import ShopPageCart from './shop/ShopPageCart';
import PageCompare from './shop/ShopPageCompare';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageComponents from './site/SitePageComponents';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageFaq from './site/SitePageFaq';
import SitePageContracts from './site/SitePageContracts';
import SitePageNotFound from './site/SitePageNotFound';
import SitePagePrivacy from './site/SitePagePrivacy';
import SitePageTerms from './site/SitePageTerms';
import SitePageTypography from './site/SitePageTypography';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageFavourites from './shop/ShopPageFavourites';
import HomePageWrapper from './home/HomePageWrapper';
import SellerPage from './shop/SellerPage'
// data stubs
import theme from '../data/theme';
import CreateNewAdDeneme from './shop/CreateNewAdDeneme';
import CreateCatalogItems from './shop/CreateCatalogItems';
import CheckoutWizard from './shop/CheckoutWizard';
import AccountPageTabs from './account/AccountPageTabs';

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={HomePageWrapper} />

                        {/*
                        // Shop
                        */}

                        <Route
                            exact
                            path="/kategori"
                            render={(props) => (
                                <ShopPageCategory {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/tedarikci/:id"
                            render={(props) => (
                                <SellerPage{...props} />
                            )}
                        />

                        <Route
                            exact
                            path="/ilan/:adId/:friendlyUrl"
                            render={(props) => (
                                <ShopPageProduct {...props} layout="columnar" />
                            )}
                        />

                        <Route exact path="/sepet" component={ShopPageCart} />

                        <Route path="/siparis/ozet" component={ShopPageOrderSuccess} />

                        <Route exact path="/favoriler" component={ShopPageFavourites} />

                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />
                        <Route exact path="/shop/create-new-ad" component={CreateNewAdDeneme} />
                        <Route exact path="/shop/create-catalog-items" component={CreateCatalogItems} />

                        {/*
                        // Blog
                        */}
                        <Redirect exact from="/blog" to="/blog/category-classic" />
                        <Route
                            exact
                            path="/blog/category-classic"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-grid"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-list"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="list" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-left-sidebar"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-classic"
                            render={(props) => (
                                <BlogPagePost {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-full"
                            render={(props) => (
                                <BlogPagePost {...props} layout="full" />
                            )}
                        />

                        {/*
                        // Account
                        */}
                        <Route exact path="/hesap/cikis/:tab?" component={AccountPageLogout} />
                        <Route exact path="/hesap/cikis" component={AccountPageLogout} />
                        <Route exact path="/hesap/onay" component={AccountConfirm} />
                        <Route exact path="/hesap/sifre-yenile" component={AccountPageForgotPassword} />
                        {user && <Route path="/hesap" component={AccountWrapper} />}

                        <Route path="/checkout" component={CheckoutWizard} />

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/site/hakkimizda" />
                        <Route exact path="/site/hakkimizda" component={SitePageAboutUs} />
                        <Route exact path="/site/components" component={SitePageComponents} />
                        <Route exact path="/site/iletisim" component={SitePageContactUs} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/sss" component={SitePageFaq} />
                        <Route exact path="/site/kosullar" component={SitePageTerms} />
                        <Route exact path="/site/gizlilik" component={SitePagePrivacy} />
                        <Route exact path="/site/typography" component={SitePageTypography} />

                        <Route
                            exact
                            path="/site/sozlesmeler/:id?"
                            render={(props) => (
                                <SitePageContracts {...props} />
                            )}
                        />
                        {/* <Route exact path="/site/sozlesmeler" component={SitePageContracts} /> */}

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </>
    );
}

Layout.propTypes = {
    /**
       * header layout (default: 'classic')
       * one of ['classic', 'compact']
       */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
       * home component
       */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
