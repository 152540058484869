import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RedBliningHeart } from '../../svg';
import AccountLayout from './AccountLayout';
import { getUserOrders } from '../../store/order/orderActions';
import { getUserAddress } from '../../store/profile/profileActions';
import { getInstitutions } from '../../store/userAccount/userAccountActions';

export class AccountWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getUserOrders();
    this.props.getUserAddress();
  }

  componentDidUpdate(prevProps, prevState) {
    const { orders, address, institutions } = this.props;
    if (JSON.stringify(orders) !== JSON.stringify(prevProps.orders)) {
      this.props.getUserOrders();
    }
    if (JSON.stringify(address) !== JSON.stringify(prevProps.address)) {
      this.props.getUserAddress();
      console.log('addres güncellendi');
    }
  }

  render() {
    return (
      <div>
        <AccountLayout {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  address: state.profile.address,
  // institutions: state.userAccount.institutions
});

const mapDispatchToProps = {
  getUserOrders,
  getUserAddress,
  // getInstitutions
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWrapper);
