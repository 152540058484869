// react
import React, {
    Fragment, useState, useEffect, useMemo,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { Link } from 'react-router-dom';
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import { Filters16Svg } from '../../svg';
import { sidebarOpen } from '../../store/sidebar';
import { mobileMenuOpen } from '../../store/mobile-menu';

const handleCountdownCompleted = (adId, isCompleted) => {
    isCompleted = true;
};

export default function ProductsView({ mergedList }) {
    const [pagedList, setPagedList] = useState([]);
    const dispatch = useDispatch();
    const handlePageChange = (items) => {
        setPagedList(items);
    };
    const count = mergedList && mergedList.length;
    const list = mergedList.map((product) => (
        <div key={product.adId} className="products-list__item">
            <ProductCard
                product={product}
                onCountdownCompleted={(adId, isCompleted) => handleCountdownCompleted(adId, isCompleted)}
            />
        </div>
    ));

    const viewOptionsClasses = classNames('view-options', 'view-options--offcanvas--mobile');

    return (
        <div className="products-view">
            <div className="products-view__options">
                <div className={viewOptionsClasses}>
                    <div className="view-options__filters-button">
                        <button type="button" className="filters-button" onClick={() => dispatch(mobileMenuOpen())}>
                            <Filters16Svg className="filters-button__icon" />
                            <span className="filters-button__title">Filtreler</span>
                            <span className="filters-button__counter">{count}</span>
                        </button>
                    </div>
                    <div className="view-options__divider" />
                </div>
            </div>

            <div className="products-view__list products-list" data-layout="list" data-with-features={false}>
                {(list && list.length > 0
                    ?
                    (
                        <div className="products-list__body">
                            {list}
                        </div>
                    )
                    :
                    (
                        <div className="mt-4 text-center">
                            <h3>
                                Şu anda bu kategoride ilan bulunmamaktadır!
                            </h3>
                            <Link to="/kategori" className="btn btn-primary">Tüm İlanlar</Link>
                        </div>
                    )
                )}
            </div>
            {/* {(mergedList && mergedList.length > 30)
                && (
                    <div className="products-view__pagination">
                        <Pagination items={mergedList} onPageChange={(items) => handlePageChange(items)} />
                    </div>
                )} */}
        </div>
    );
}
ProductsView.propTypes = {
    /**
       * array of product objects
       */
    products: PropTypes.array,
    /**
       * indicates when sidebar bar should be off canvas
       */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsView.defaultProps = {
    products: [],
    offcanvas: 'mobile',
};
