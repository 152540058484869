// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import moment from 'moment';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    TelegramIcon,
    FacebookIcon,
    WhatsappIcon,
} from 'react-share';
import FlipNumbers from 'react-flip-numbers';
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartUpdate } from '../../store/cart';
import {
    Wishlist16Svg, Compare16Svg, RedBliningHeart, Cart16Svg, InfoIcon,
} from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import 'moment/locale/tr';
import FollowButton from './FollowButton';
import ReactTooltip from 'react-tooltip';
import Blink from './Blink';
import ToolTip from './ToolTip';
import RatingPoint from '../shared/RatingPoint'
import ChartView from './ChartView'

function Product(props) {
    const { product, layout, favorites, cartUpdate, cart} = props;
    const cartId = cart.info && (cart.info.uuid || null);
    const [amount, setAmount] = useState(1);
    const [styleAnimationWidth, setStyleAnimationWidth] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setStyleAnimationWidth(product.instantDiscountPercent);
        }, 500);
    }, [product.instantDiscountPercent]);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)

        if (queryParams.has('ref')) {
            const ref = queryParams.get('ref');
            console.log(ref);
            queryParams.delete('ref')
            history.replace({
                search: queryParams.toString(),
            })
        }
    }, [location, history])


    const calculateProgressBarWidth = styleAnimationWidth / product.maxDiscountPercent;

    const handleChangeAmount = (amount) => {
        setAmount(amount);
    };
    const orderCount = product && product.numOrders;
    let price;
    const salePrice = `${(product.productPrice).toLocaleString(undefined,
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}${' '}₺`;

    let isFollowing = false;
    const [updatedParticipants, setUpdatedParticipants] = useState((product && product.participants) || 0);
    const onAdFollow = (participants) => {
        setUpdatedParticipants(participants);
    };

    const adName = product && (`${product.categoryName} ${product.brandName} ${product.modelName}`);
    const friendlyUrl = adName && adName.replace(/\s+/g, '-').toLowerCase();
    favorites && favorites.map((fav) => {
        if (fav.adId === product.adId) {
            isFollowing = true;
        }
    });

    let instantPrice;
    let instantDiscount = '0';
    let instantProfit;

    if (product.instantPrice) {
        instantPrice = `${(product.instantPrice).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${' '}₺`;

        instantProfit = `${((product.productPrice - product.instantPrice)).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${' '}₺`;
    }
    let listPrice;
    let targetPrice;

    const setPriceName = () => {
        let label = ''
        if (product.instantPrice <= product.productPrice) {
            label = 'İlk Fiyat'
        }
        if (product.instantPrice > product.targetPrice && product.instantPrice < product.productPrice) {
            label = 'Anlık Fiyat'
        }
        if (product.instantPrice === product.targetPrice) {
            label = 'Son Fiyat'
        }
        return label;
    }


    if (product.targetPrice) {
        targetPrice = `${(product.targetPrice).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${' '}₺`;
    }

    if (product.instantDiscountPercent) {
        instantDiscount = `% ${(product.instantDiscountPercent).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}`;
    }

    listPrice = (
        <div style={{ paddingBottom: '5px', paddingTop: '15px' }}>
            <span style={{
                color: '#9ab2b3',
                fontSize: '13px',
                fontWeight: 'lighter',
                paddingRight: '5px',
                color: '#006a7c',
            }}
            >
                Başlangıç Fiyatı:
            </span>
            <span className="text-danger" style={{ textDecoration: 'line-through' }}>
                <Currency value={product.productPrice} />
            </span>
        </div>
    );

    if (product.instantPrice) {
        instantPrice = `${(product.instantPrice).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${' '}₺`;

        instantProfit = `${(product.productPrice - product.instantPrice).toLocaleString(undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${' '}₺`;

        price = (
            <div
                className="price-field__labels__wrapper-down"
                style={{ paddingBottom: '15px', paddingTop: '5px' }}
            >
                <span style={{
                    fontSize: '13px',
                    lineHeight: '13px',
                    paddingRight: '5px',
                    color: '#006a7c',
                }}
                >
                    İade:
                </span>
                <span className="text-success">
                    <FlipNumbers
                        play
                        width={13}
                        height={20}
                        numbers={`${instantProfit}`}
                        duration={2}
                    />
                </span>
            </div>
        );
    }

    let images = [];
    if (product && product.imageUrl) {
        images = product.images.map(a => a.url);

        // for(i in product.images) {
        //     let im = product.images[i].url;
        // }
        // // images = [product.imageUrl];
        // images[0] = (product.imageUrl);
    }

    const shareSheet = (url) => {
        const shareData = {
            title: 'Ortak Satın Almaya Davet Edildin...',
            text: 'Ortak satın alma platformu!',
            url,
        };
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => {
                }).catch((err) => {
                    console.log(err);
                });
        }
    };
    const ad_finish_date = moment(product.endDate);
    moment().locale('tr');

    const CompletionList = () => (
        <div style={{ paddingTop: '10px' }}>
            <h6>İlan Süresi Dolmuştur!</h6>
        </div>
    );
    const renderer = ({
        days, hours, minutes, seconds, completed,
    }) => {
        if (completed) {
            return <CompletionList />;
        }
        // Render a countdown
        return (
            <div>
                <div className="countdown_container">
                    {days !== 0 ? (
                        <div className="countdown-item">
                            {days === 0 ? 0 : days}
                            <span>gün</span>
                        </div>
                    ) : null}
                    {hours !== 0 ? (
                        <div className="countdown-item">
                            {hours === 0 ? 0 : hours}
                            <span>saat</span>
                        </div>
                    ) : null}
                    {minutes !== 0
                        ? (
                            <div className="countdown-item">
                                {minutes === 0 ? 0 : minutes}
                                <span>dakika</span>
                            </div>
                        ) : null}
                    {
                        <div className="countdown-item">
                            {seconds === 0 ? 0 : seconds}
                            <span>saniye</span>
                        </div>
                    }
                </div>
            </div>
        );
    };
    return (
        <>
            <Helmet>
                <title>{`${product.brandName} ${product.modelName} ${product.instantPrice} TL'ye`}</title>
                <meta name="description" content={`${product.categoryName} ${product.brandName} ${product.modelName}`} />
                <meta property="og:type" content="https://bizleal.com" />
                <meta property="og:image" content={`${product.image}`} />
                <meta property="og:url" content={`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`} />
            </Helmet>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <ProductGallery layout={layout} images={images} />
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                    <div className="row">
                        <div className="col-10 col-lg-4">
                            <div className="bizleal_product_detail_title_first">
                                {product.brandName}
                                {' '}
                                {product.modelName}
                            </div>
                            <div className="bizleal_product_detail_title_second">{product.description}</div>
                        </div>
                        <div className="col-2 col-lg-2">
                            <div className="bizleal_product_detail_like">
                                <FollowButton
                                    adId={product.adId}

                                    isFollowing={isFollowing}
                                    onFollow={(participants) => onAdFollow(participants)}
                                    numOfParticipants={updatedParticipants}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                            <Countdown
                                date={ad_finish_date.toDate()}
                                renderer={renderer}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-6">
                            {/* <div
                                data-tip
                                data-for="progressbar"
                                className="bizleal_product_detail_progressbar" >
                                <div className="item_progress" style={{ '--tip-left': calculateProgressBarWidth }}>
                                    <div className="item_progress_bar" />
                                    <div className="progress_tip">
                                        <div className="tip_title d-flex flex-column align-items-center">
                                            <span style={{ fontWeight: '100', color: 'black' }}>{setPriceName()}</span>
                                            {'  '}
                                            <span style={{ fontWeight: '200', fontFamily: 'sans-serif', color: 'green' }}>
                                                <FlipNumbers
                                                    play
                                                    width={12}
                                                    height={18}
                                                    numbers={`${instantPrice}`}
                                                    duration={2}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="progress_tip reverse">
                                        <div className="tip_title d-flex flex-column align-items-center">
                                            <span style={{ fontWeight: '200', fontFamily: 'sans-serif', color: 'green' }}>
                                                <FlipNumbers
                                                    play
                                                    width={12}
                                                    height={18}
                                                    numbers={`${instantProfit}`}
                                                    duration={2}
                                                />
                                            </span>
                                            <span style={{ fontWeight: '100', color: 'black' }}>İade</span>
                                        </div>
                                    </div>
                                </div>
                                <ToolTip id={'progressbar'} text={'Ürün fiyatı sipariş sayısına göre azalacaktır.'} />
                            </div> */}
                            <ChartView product={product} />
                            <div className="row mt-3 mt-sm-0">
                                <div className="col-12 d-flex justify-content-between my-2">
                                    {product.numOrders > 0 &&
                                        <div style={{ fontSize: '12px', display: 'inline-block' }} className="d-flex align-items-baseline">
                                            Satılan:&nbsp;
                                            <span className="text-success">
                                                {product.numOrders}
                                            </span>
                                        </div>}
                                    {product.numOrders > 0 &&
                                        <div style={{ fontSize: '12px', display: 'inline-block' }} className="d-flex align-items-baseline">
                                            Kalan:&nbsp;
                                            <span className="text-danger">
                                                {product.quantity - product.numOrders}
                                            </span>
                                        </div>}
                                    <div style={{ fontSize: '12px' }} className="d-flex align-items-baseline">
                                        Stok:&nbsp;
                                        <span className="text-info">
                                            {product.quantity}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="row mt-3 mt-lg-0">
                                <div className="col-12 d-flex" data-tip data-for="salePriceInfo">
                                    <span><InfoIcon /></span>
                                    <span style={{ fontSize: '12px' }} className="pl-2">
                                        Ürünler satış fiyatından alınır</span>
                                </div>
                                <ToolTip id={'salePriceInfo'}
                                    text={'Ürünün son fiyatı ancak ilan süresi sonunda netleşeceği için, başlangıçta her üyemiz ilk satış fiyatını öder'} />
                                <div
                                    className="col-12 d-flex my-3"
                                    data-tip data-for="discountInfo"
                                >
                                    <span><InfoIcon /></span>
                                    <span style={{ fontSize: '12px' }} className="pl-2">
                                        Toplam satın alma adedine göre fiyat düşer&nbsp;
                                        {(product.instantPrice < product.productPrice) && <span className="text-success">{`(${instantPrice})`}</span>}
                                    </span>
                                </div>
                                <ToolTip id={'discountInfo'}
                                    text={'Ürünün fiyatı, üyelerin toplu satın alma adedine göre düşecektir'} />
                                <div
                                    className="col-12 d-flex mb-3"
                                    data-tip data-for="diffInfo"
                                >
                                    <span><InfoIcon /></span>
                                    <span style={{ fontSize: '12px' }} className="pl-2">
                                        İlan süresi sonunda, fiyat farkı hesabınıza iade edilir&nbsp;
                                        {(product.productPrice - product.instantPrice) > 0 && <span className="text-success">{`(${instantProfit})`}</span>}
                                    </span>
                                </div>
                                <ToolTip id={'diffInfo'}
                                    text={'Ödediğiniz ilk satış fiyatından, ilan süresi sonunda netleşen son fiyat çıkartılıp hesabınıza iade edilir'} />
                                <div
                                    className="col-12 d-flex"
                                    data-tip data-for="shareInfo"
                                >
                                    <span><InfoIcon /></span>
                                    <span style={{ fontSize: '12px' }} className="pl-2 text-info">
                                        İlanı arkadaşlarınıza paylaşarak, ürün fiyatının düşmesine katkı sağlayabilirsiniz
                                    </span>
                                </div>
                                <ToolTip id={'shareInfo'}
                                    text={'İlanı sosyal medyada, whatsapp ve telegram gibi uygulamarda paylaşarak, ürün fiyatının düşmesine katkı sağlayabilirsiniz.'} />
                                <div className="col-12 d-flex align-items-center justify-content-around my-4">
                                    <span>
                                        <WhatsappShareButton
                                            data-tip
                                            //   data-for="share"
                                            quote="title"
                                            description={"deneme"}
                                            url={`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`}
                                        >
                                            <WhatsappIcon
                                                size={32}
                                                borderRadius={10}
                                            />
                                        </WhatsappShareButton>
                                    </span>
                                    {navigator.share ?
                                        <span>
                                            <button data-tip data-for="share"
                                                className="btn btn-plain"
                                                quote={'title'}
                                                url={`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`}
                                                onClick={() => { shareSheet(`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`) }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" /><polyline points="16 6 12 2 8 6" /><line x1="12" y1="2" x2="12" y2="15" /></svg>
                                            </button>
                                        </span>
                                        :
                                        <span>
                                            <FacebookShareButton
                                                data-tip data-for="share"
                                                quote="title"
                                                url={`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`}
                                            >
                                                <FacebookIcon
                                                    size={32} borderRadius={10}
                                                />
                                            </FacebookShareButton>
                                        </span>
                                    }
                                    <span>
                                        <TelegramShareButton
                                            data-tip
                                            data-for="share"
                                            quote="title"
                                            url={`https://bizleal.com/ilan/${product.adId}/${friendlyUrl}`}
                                        >
                                            <TelegramIcon
                                                size={32}
                                                borderRadius={10}
                                            />
                                        </TelegramShareButton>
                                    </span>
                                </div>
                                <div className="col-12 d-flex align-items-baseline">
                                    <span style={{ fontSize: '14px' }} className="pl-2 d-flex align-items-baseline">
                                        Satıcı:&nbsp;
                                        <Link to={`/tedarikci/${product.sellerId}`} style={{ color: '#f1861d' }}>{product.sellerName}</Link>
                                    </span>&nbsp;
                                    <RatingPoint value={8.9} />
                                </div>

                                <div className="card container d-none d-md-flex m-3">
                                    <div className="card_body">
                                        <div className="row my-3">
                                            <div className="col-12 d-flex align-items-center justify-content-between"
                                                data-tip data-for="salesArea">
                                                <div>
                                                    <span><InfoIcon /></span>
                                                    <span className="ml-2" style={{ fontSize: '15px', fontFamily: 'Helvetica' }}>
                                                        Satış Fiyatı:
                                                    </span>
                                                    <ToolTip id={'salesArea'}
                                                        text={'Kartınızdan en başta çekilecek tutar'} />
                                                </div>
                                                <span style={{ fontSize: '21px' }}>
                                                    {salePrice}
                                                </span>
                                            </div>
                                            <div className="col-12 d-flex align-items-center justify-content-between mt-3">
                                                <span>
                                                    <InputNumber
                                                        id="product-amount"
                                                        aria-label="amount"
                                                        className="product__amount"
                                                        size="sm"
                                                        min={1}
                                                        value={amount}
                                                        onChange={handleChangeAmount}
                                                        readOnly
                                                    />
                                                </span>
                                                <span>
                                                    <AsyncAction
                                                        action={() => cartUpdate(cartId, product.adId, amount)}
                                                        render={({ run, loading }) => (
                                                            <button
                                                                style={{ borderRadius: '10px' }}
                                                                type="submit"
                                                                className={classNames('btn btn-primary', {
                                                                    'btn-loading': loading,
                                                                })}
                                                                onClick={run}
                                                            >

                                                                <span className="mr-3"><Cart16Svg /></span>
                                                                <span style={{ fontSize: '14px' }}>
                                                                    Sepete Ekle
                                                                </span>
                                                            </button>
                                                        )}
                                                    />
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="shop_fixed_all">
                                    <div className="shop_fixed_content d-md-none">
                                        <div className="container">

                                            <div className="row">
                                                <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                                    <span style={{ fontSize: '15px', fontFamily: 'Helvetica' }}>
                                                        Satış Fiyatı:
                                                    </span>
                                                    <span style={{ fontSize: '21px' }}>
                                                        {salePrice}
                                                    </span>
                                                </div>
                                                <div className="col-12 d-flex align-items-center justify-content-between">
                                                    <span>
                                                        <InputNumber
                                                            id="product-amount"
                                                            aria-label="Amount"
                                                            className="product__amount"
                                                            size="sm"
                                                            min={1}
                                                            value={amount}
                                                            onChange={handleChangeAmount}
                                                            readOnly
                                                        />
                                                    </span>
                                                    <span>
                                                        <AsyncAction
                                                            action={() => cartUpdate(cartId, product.adId, amount)}
                                                            render={({ run, loading }) => (
                                                                <button
                                                                    style={{ borderRadius: '10px' }}
                                                                    type="submit"
                                                                    className={classNames('btn btn-primary', {
                                                                        'btn-loading': loading,
                                                                    })}
                                                                    onClick={run}
                                                                >

                                                                    <span className="mr-3"><Cart16Svg /></span>
                                                                    <span style={{ fontSize: '14px' }}>
                                                                        Sepete Ekle
                                                                    </span>
                                                                </button>
                                                            )}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    favorites: state.userAccount.favorites,
    cart: state.cart,
});
const mapDispatchToProps = {
    cartUpdate,
    wishlistAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product);

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};
