// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
};

class BlockSlideShowDeneme extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
      if (this.media.addEventListener) {
        this.media.addEventListener('change', this.onChangeMedia);
      } else {
        this.media.addListener(this.onChangeMedia);
      }
    }

    componentWillUnmount() {
      departmentsAria.area = null;

      if (this.media.removeEventListener) {
        this.media.removeEventListener('change', this.onChangeMedia);
      } else {
        this.media.removeListener(this.onChangeMedia);
      }
    }

    onChangeMedia = () => {
      if (this.media.matches) {
        departmentsAria.area = this.departmentsAreaRef;
      }
    };

    setDepartmentsAreaRef = (ref) => {
      this.departmentsAreaRef = ref;

      if (this.media.matches) {
        departmentsAria.area = this.departmentsAreaRef;
      }
    };

    render() {
      const {
        mergedList, locale, withDepartments, projectPhotos,
      } = this.props;
        // const slides = this.modifyProjectPhotos(projectPhotos)
      const { direction } = languages[locale];

      const blockClasses = classNames(
        'home_slideshow',
        'block-slideshow block',
        'block-slideshow--layout--full',
      );

      const slides = [
        {
          title: "Türkiye'nin <span style=\"color: white;font-size:25px; background-color:#f1861E;display:inline-block;padding: 1px 5px; border-radius:5px\">Ortak Satın Alma</span><br />Platformu",
          text: '<p>Ortak ihtiyaçlarınız için, organize olun...</p>',
          image_desktop: 'images/slides/banner-shop.png',
          image_mobile: 'images/slides/banner-shop-mobile.png',
        },
        {
          title: 'Doğrudan Üreticiden <span style="color: white;font-size:20px; background-color:#f1861E;display:inline-block;padding: 1px 5px; border-radius:5px">Ortak Satın Alma</span> Yapabilirsiniz',
          text: '<p>Aynı toptancı gibi, birleşerek en uygun fiyatlara satın alma yapabilirsiniz...</p>',
          image_desktop: 'images/slides/group-buy.png',
          image_mobile: 'images/slides/group-buy-mobile.png',
        },
        {
          title: 'Büyük Firmaların <span style="color: white;font-size:20px; background-color:#f1861E;display:inline-block;padding: 1px 5px; border-radius:5px">Satın Alma Gücüne</span> Ulaşabilirsiniz',
          text: '<p>Son kullanıcılar olarak, güçlerinizi birleştirip, büyük firmalar karşısında daha güçlü olabilirsiiniz...</p>',
          image_desktop: 'images/slides/little-fish.png',
          image_mobile: 'images/slides/little-fish-mobile.png',

        },
      ];

      const slidesFunction = slides.map((slide, index) => {
        const image = (slide.image_desktop);
        return (
          <div key={index} className="block-slideshow__slide">
            <div
              className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
              style={{
                backgroundImage: `url(${slide.image_desktop})`,
              }}
            />
            <div
              className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
              style={{
                backgroundImage: `url(${slide.image_mobile})`,
              }}
            />
            <div className="block-slideshow__slide-content">
              <div
                className="block-slideshow__slide-title"
                dangerouslySetInnerHTML={{ __html: slide.title }}
              />
              <div
                className="block-slideshow__slide-text"
                dangerouslySetInnerHTML={{ __html: slide.text }}
              />
            </div>
          </div>
        );
      });

      return (
        <div className={blockClasses} data-layout="grid-1">
          <div className="block-slideshow__body">
            <StroykaSlick {...slickSettings}>
              {slidesFunction}
            </StroykaSlick>
          </div>
        </div>

      // <div className={blockClasses} >

      //     <StroykaSlick {...slickSettings}>
      //         {slidesFunction}
      //     </StroykaSlick>

      // </div>
      );
    }
}

BlockSlideShowDeneme.propTypes = {
  withDepartments: PropTypes.bool,
  /** current locale */
  locale: PropTypes.string,
};

BlockSlideShowDeneme.defaultProps = {
  withDepartments: false,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
  projectPhotos: state.photo.projectPhotos,
});

export default connect(mapStateToProps)(BlockSlideShowDeneme);
