export default [
    {
        title: '₺ Türk Lirası',
        currency: {
            code: 'TRY',
            symbol: '₺',
            name: 'Türk Lirası',
        },
    },
    // {
    //     title: '€ Euro',
    //     currency: {
    //         code: 'EUR',
    //         symbol: '€',
    //         name: 'Euro',
    //     },
    // },
    // {
    //     title: '£ Pound Sterling',
    //     currency: {
    //         code: 'GBP',
    //         symbol: '£',
    //         name: 'Pound Sterling',
    //     },
    // },
    // {
    //     title: '$ US Dollar',
    //     currency: {
    //         code: 'USD',
    //         symbol: '$',
    //         name: 'US Dollar',
    //     },
    // },
    // {
    //     title: '₽ Russian Ruble',
    //     currency: {
    //         code: 'RUB',
    //         symbol: '₽',
    //         name: 'Russian Ruble',
    //     },
    // },
];
