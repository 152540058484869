// react
import React, { useState, useEffect, useRef } from 'react';

// third-party
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// application
import { toast } from 'react-toastify';
import AsyncAction from '../shared/AsyncAction';
import IndicatorDeneme from './IndicatorDeneme';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartUpdate, cartGet } from '../../store/cart/cartActions';

export default function IndicatorCart(props) {
    const { cart } = useSelector((state) => ({
        cart: state.cart,
    }), shallowEqual);
    const [cartInstance, setCartInstance] = useState({});
    const dispatch = useDispatch();
    const {
        info, taxes, items, isLoading,
    } = cartInstance;
    const cartId = info && info.uuid;
    const status = info && info.status;

    useEffect(() => {
        if (status) {
            dispatch(cartGet(cartId));
        }
    }, [status, dispatch]);

    useEffect(() => {
        setCartInstance(cart);
    }, [cart]);

    let dropdown;
    let totals;
    const user = JSON.parse(localStorage.getItem('user'));

    const prePayment = (
        <tr>
            <th><span className="text-success" style={{ fontSize: 'smaller' }}>İade</span></th>
            <td>
                <span className="text-success">{info && info.totalProfit}</span>
            </td>
        </tr>
    );
    totals = (
        <>
            <tr>
                <th>Ürünler Toplamı</th>
                <td>
                    {info && info.subTotal}
                    {' '}
                </td>
            </tr>
            {prePayment}
        </>
    );

    const handleClick = () => {
        toast.info('Siparişi onaylamak için giriş yapınız!');
    };

    const itemList = items && items.map((item) => {
        const adName = item.product && (`${item.product.categoryName} ${item.product.brandName} ${item.product.modelName}`);
        const friendlyUrl = adName && adName.replace(/\s+/g, '-').toLowerCase();
        let options;
        let image;

        if (item.product.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.product.options.map((option, index) => (
                        <li key={index}>{`${option.name}: ${option.value}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product && item.product.imageUrl) {
            image = (
                <div className="dropcart__product-image">
                    <Link to={`/ilan/${item.product.adId}/${friendlyUrl}`}>
                        <img src={item.product.imageUrl} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => dispatch(cartUpdate(cartId, item.adId, 0))}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.adId} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <Link to={`/ilan/${item.product.adId}/${friendlyUrl}`}
                        className="dropcart__product-name">
                        {item.product.brandName}
                        {' '}
                        {item.product.modelName}
                        {options}
                    </Link>
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.amount}</span>
                        {' × '}
                        <span className="dropcart__product-price">{item.totalPrice}</span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (items && items.length > 0 && status === 'created') {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {/* {cart.item.product.brandName} */}
                    {itemList}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/sepet">Sepet</Link>
                    {user ? <Link className="btn btn-primary" to="/checkout#delivery">Sipariş Onay</Link>
                        : (
                            <Link className="btn btn-primary" to="/hesap/cikis" onClick={() => handleClick()}>
                                Sepeti Onayla
                            </Link>
                        )}
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    Sipairşiniz bulunmamaktadır!
                </div>
            </div>
        );
    }

    return (
        <span>
            {info && info.status === 'created' ? (
                <IndicatorDeneme
                    url="/sepet"
                    dropdown={dropdown}
                    value={items && items.length || undefined}
                    icon={<Cart20Svg />}
                />
            )
                : (
                    <IndicatorDeneme
                        url="/sepet"
                        dropdown={dropdown}
                        value={undefined}
                        icon={<Cart20Svg />}
                    />
                )}
        </span>
    );
}
