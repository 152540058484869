import React, { Component } from 'react';
import { connect } from 'react-redux';
// third-party
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import IndicatorDeneme from './IndicatorDeneme';
import { Person20Svg } from '../../svg';
import { login } from '../../store/userAccount/userAccountActions';

export class IndicatorAccountDispatch extends Component {
  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    let dropDown;
    if (user) {
      dropDown = (
        <div className="account-menu">
          <Link to="/hesap/bilgiler" className="account-menu__user">
            <div className="account-menu__user-avatar">
              <img src={user.image} alt="" />
            </div>
            <div className="account-menu__user-info">
              <div className="account-menu__user-name">
                {user.firstName}
                {' '}
                {user.lastName}
              </div>
              <div className="account-menu__user-email">{user.email}</div>
            </div>
          </Link>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li><Link to="/hesap/profil">Profili Düzenle</Link></li>
            <li><Link to="/hesap/siparis">Siparişlerim</Link></li>
            <li><Link to="/hesap/adres">Adres Bilgilerim</Link></li>
            <li><Link to="/hesap/sifre">Şifre İşlemlerim</Link></li>
          </ul>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li><Link to="/hesap/cikis">Çıkış</Link></li>
          </ul>
        </div>
      );
      return (
        <IndicatorDeneme url="/hesap" dropdown={dropDown} icon={<Person20Svg />} />
      );
    }

    dropDown = (
      <div className="account-menu-avatar-icon">
        <div className="form-group-avatar-icon account-menu__form-button-user-icon">
          <Link to="/hesap/cikis/1">
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <button type="submit" className="btn btn-primary btn-sm btn-block">Giriş Yap</button>
            </div>
          </Link>
        </div>
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div className="account-menu__divider" />
        </div>
        <div className="form-group-avatar-icon account-menu__form-button-user-icon">
          <Link to="/hesap/cikis/2">
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <button type="submit" className="btn btn-secondary btn-sm btn-block">Üye Ol</button>
            </div>
          </Link>
        </div>

      </div>
    );
    return (
      <IndicatorDeneme url="/hesap/cikis" dropdown={dropDown} icon={<Person20Svg />} />
    );
  }
}
