import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,

    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAIL,

    FOLLOW,
    FOLLOW_SUCCESS,
    FOLLOW_FAIL,

    UNFOLLOW,
    UNFOLLOW_SUCCESS,
    UNFOLLOW_FAIL,

    FETCH_FAVORITES,
    FETCH_FAVORITES_SUCCESS,
    FETCH_FAVORITES_FAIL,
    LOGOUT,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,

    FETCH_INSTITUTIONS,
    FETCH_INSTITUTIONS_SUCCESS,
    FETCH_INSTITUTIONS_FAIL,

} from './userAccountActions';

const initialState = {
    user: {},
    favorites: [],
    isLoading: false,
    isLogin: false,
    error: null,
    institutions: [],
};
const updateFavorites = (state, items) => {
    const newFavorites = [...items];
    return {
        ...state,
        favorites: newFavorites,
        isLoading: false,
    };
};
export default function userAccountReducer(state = initialState, action) {
    switch (action.type) {
    case LOGIN:
        return {
            ...state,
            isLoading: true,
            isLogin: true,
        };
    case LOGIN_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isLogin: false,
            user: action.payload,
        };
    case LOGIN_FAIL:
        return {
            ...state,
            error: action.payload,
            isLogin: false,
        };
    case REGISTER:
        return {
            ...state,
            isLogin: true,
        };
    case REGISTER_SUCCESS:
        return {
            ...state,
            isLogin: false,
        };
    case REGISTER_FAIL:
        return {
            ...state,
            error: action.payload,
            isLogin: false,
        };
    case LOGOUT:
        return {
            ...state,
            isLoading: false,
            user: null,
            favorites: [],
            isLogin: false,
        };

    case FOLLOW:
        return {
            ...state,
            isLoading: true,
        };
    case FOLLOW_SUCCESS:
        return updateFavorites(state, action.payload);
    case FOLLOW_FAIL:
        return {
            ...state,
            error: action.payload,
            isLoading: false,
        };
    case UNFOLLOW:
        return {
            ...state,
            isLoading: true,
        };
    case UNFOLLOW_SUCCESS:
        return updateFavorites(state, action.payload);
        // return {
        //     ...state,
        //     favorites: action.payload,
        //     isLoading: false
        // };
    case UNFOLLOW_FAIL:
        return {
            ...state,
            error: action.payload,
            isLoading: false,
        };
    case FETCH_FAVORITES:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_FAVORITES_SUCCESS:
        return updateFavorites(state, action.payload);
    case FETCH_FAVORITES_FAIL:
        return {
            ...state,
            error: action.payload,
            isLoading: false,
        };
    case CHANGE_PASSWORD:
        return {
            ...state,
            isLoading: true,
        };
    case CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            isLoading: false,
        };
    case CHANGE_PASSWORD_FAIL:
        return {
            ...state,
            error: action.payload,
            isLoading: false,
        };
    case FETCH_INSTITUTIONS:
        return {
            ...state,
            isLoading: true,
        };
    case FETCH_INSTITUTIONS_SUCCESS:
        return {
            ...state,
            institutions: action.payload,
        };
    case FETCH_INSTITUTIONS_FAIL:
        return {
            ...state,
            error: action.payload,
            isLoading: false,
        };
    default:
        return state;
    }
}
