// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../header/Indicator';
import {
    Menu18x14Svg,
    // LogoSmallSvg,
    Search20Svg,
    Heart20Svg,
    Cart20Svg,
    Person20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import MobileLogo from '../../svg/mobilelogo.svg';

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        const {
            openMobileMenu, wishlist, cart, favourites,
        } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });
        let dropdown;
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            dropdown = (
                <div className="account-menu">
                    <Link to="/hesap/bilgiler" className="account-menu__user">
                        <div className="account-menu__user-avatar">
                            <img src="images/avatars/avatar-3.jpg" alt="" />
                        </div>
                        <div className="account-menu__user-info">
                            <div className="account-menu__user-name">
                                {user.firstName}
                                {' '}
                                {user.lastName}
                            </div>
                            <div className="account-menu__user-email">{user.email}</div>
                        </div>
                    </Link>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li><Link to="/hesap/profil">Profili Düzenle</Link></li>
                        <li><Link to="/hesap/siparis">Siparişlerim</Link></li>
                        <li><Link to="/hesap/adres">Adres Bilgisi</Link></li>
                        <li><Link to="/hesap/sifre">Şifre İşlemleri</Link></li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li><Link to="/hesap/cikis">Çıkış</Link></li>
                    </ul>
                </div>
            );
        } else {
            dropdown = (
                <div className="account-menu-avatar-icon">
                    <div className="form-group-avatar-icon account-menu__form-button-user-icon">
                        <Link to="/hesap/cikis/1">
                            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                <button type="submit" className="btn btn-primary btn-sm btn-block">Giriş Yap</button>
                            </div>
                        </Link>
                    </div>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className="account-menu__divider" />
                    </div>
                    <div className="form-group-avatar-icon account-menu__form-button-user-icon">
                        <Link to="/hesap/cikis/2">
                            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                <button type="submit" className="btn btn-secondary btn-sm btn-block">Üye Ol</button>
                            </div>
                        </Link>
                    </div>

                </div>
            );
        }

        return (
            <div className="mobile-header mb-4">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <Menu18x14Svg />
                            </button>
                            <Link to="/">
                                <img
                                    src={MobileLogo}
                                    alt="Logo"
                                    className="logo-bizleal"
                                />
                            </Link>
                            {/* <Link to="/" className="mobile-header__logo"><LogoSmallSvg /></Link> */}
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                {user && (
                                    <Indicator
                                        url={user ? '/favoriler' : '/hesap/cikis'}
                                        value={(favourites && (favourites.length > 0 ? favourites.length : undefined)) || undefined}
                                        icon={<Heart20Svg />}
                                    />
                                )}
                                <Indicator
                                    className="indicator--mobile"
                                    url="/sepet"
                                    value={((cart.items.length > 0 && cart.info.status === 'created') ? cart.items.length : undefined)}
                                    icon={<Cart20Svg />}
                                />
                                <Indicator
                                    url="/hesap"
                                    className="indicator--mobile"
                                    dropdown={dropdown}
                                    icon={<Person20Svg />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
    favourites: state.userAccount.favorites,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
