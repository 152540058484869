// react
import React, {
    Fragment, useState, useEffect, useRef,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import { API_BASE } from '../../env';
import { history, authHeaderWithSecret } from '../../helpers';
// application
import classNames from 'classnames';
import { toast } from 'react-toastify';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import Select from 'react-select'


export default function ReturnProductModal(props) {
    const { productItem, show, onHide, hideReturnProductButton, reasons, cartId } = props;
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [returnAmount, setReturnAmount] = useState(0);
    const [reason, setReason] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        sendEvaluation();
    };
    const resetToInitialValues = () => {
        setComment('')
        onHide();
    };

    const handleChange = (e) => {
        e.preventDefault();
        setComment(e.target.value);
    };
    const sendEvaluation = () => {
        setIsLoading(true)
        axios.post(`${API_BASE}/order/returnProduct`,
            { cartId, returnAmount, reason, comment },
            { headers: authHeaderWithSecret() })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Ürün iade talebiniz başarıyla alındı")
                    setIsLoading(false);
                    resetToInitialValues();
                    hideReturnProductButton();
                }
            }).catch((err) => {
                toast.error("Ürün iade talebi oluştururken hata oluştu!")
                setIsLoading(false)
            });
    }


    const handleAmountChange = ({ value }) => {
        setReturnAmount(value)
    }
    const handleReasonChange = ({ label }) => {
        setReason(label)
    }
    const clearState = () => {
        setComment('');
    }
    const options = []
    reasons && reasons.filter(item => {
        if (item.formtype === "return" || item.formtype === "both") {
            let obj = { value: item.id, label: item.reasonText }
            options.push(obj)
        }
    });

    let amountArray = [];
    for (let i = 1; i <= productItem?.returnableAmount; i++) {
        let obj = { value: i, label: i }
        amountArray.push(obj)
    }
    const productPrice = (value) => {
        return parseFloat(value.replace(/,/g, ''));
    }

    const thousandSeperator = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const enabled = (reason.length > 0 && (returnAmount > 0 || productItem?.returnableAmount === 1))


    return (
        <Modal isOpen={show} centered unmountOnClose>
            <form onSubmit={handleSubmit}>
                <div className="modal-header">
                    <h5 className="modal-title">Kolay İade</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={resetToInitialValues}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <div className="card mb-3">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-4">
                                    <div className="cart-table__column--image">
                                        <img src={productItem.product.imageUrl} alt="" />
                                    </div>
                                </div>
                                <div className="col-8 d-flex flex-column justify-content-center align-items-start">
                                    <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                        {productItem.product.brandName}
                                    </span>
                                    <span style={{ fontSize: '12px' }}>
                                        {productItem.product.modelName}
                                    </span>
                                    <span style={{ fontSize: '12px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Satın Alınan Adet:</span>&nbsp;{productItem.amount}
                                    </span>
                                    <div style={{ fontSize: '12px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Toplam:</span>&nbsp;{productItem.totalPrice}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label hstyle={{ fontSize: '15px', fontWeight: 'bolder' }}>Neden iade etmek istiyorsunuz</label>
                                <Select options={options}
                                    placeholder="İade sebebinizi giriniz..."
                                    onChange={handleReasonChange}
                                />
                            </div>
                        </div>
                    </div>
                    {productItem.returnableAmount > 1
                        ? <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label style={{ fontSize: '15px' }}>Kaç Adet Ürün İade Etmek İstiyorsunuz</label>
                                    <Select
                                        options={amountArray}
                                        onChange={handleAmountChange}
                                        placeholder="İade edeceğiniz ürün adedini giriniz..."
                                    />
                                </div>
                            </div>
                        </div>
                        : (<div className="col-12">
                            <div className="form-group">
                                <label style={{ fontSize: '15px' }}>İade Edilecek Tutar:&nbsp;&nbsp;
                                    <span style={{ fontSize: '15px', color: 'green', fontWeight: 'bold' }}>
                                        {thousandSeperator((productPrice(productItem.product.normalPrice)) + '.00 ₺')}
                                    </span>
                                </label>
                            </div>
                        </div>)
                    }
                    {returnAmount > 0 ? <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label style={{ fontSize: '15px' }}>İade Edilecek Tutar:&nbsp;&nbsp;
                                    <span style={{ fontSize: '15px', color: 'green', fontWeight: 'bold' }}>
                                        {thousandSeperator((returnAmount * productPrice(productItem.product.normalPrice)) + '.00 ₺')}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div> : null}

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    name="comment"
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange}
                                    placeholder='Satıcıya iletmek istediğiniz herhangi bir not var mı?'
                                    value={comment}
                                    rows={6}
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    </div>
                    <span style={{ fontSize: '12px', color: 'red' }}>
                        Lütfen notunuzun genel ahlak kurallarına aykırı, müstehcen, siyasi veya yasal olmayan içerik <b style={{ textDecoration: 'underline' }}>bulundurmadığına</b>
                        {' '}
                        dikkat ediniz!
                        {' '}
                    </span>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="submit"
                        className={classNames('btn btn-primary btn-block', {
                            'btn-loading': isLoading,
                        })}
                        style={{ borderRadius: '5px' }}
                        disabled={!enabled}
                    >
                        Ürünü İade Et
                    </button>
                </ModalFooter>
            </form>
        </Modal>

    );
}
