// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
  Link,
  matchPath,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';
import AccountPageLogout from './AccountPageLogout';
import AddressModal from '../shop/AddressModal';
import ShopPagePayment from '../shop/ShopPagePayment';
import AccountPageOrders2 from './AccountPageOrders2';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageReviews from './AccountPageReviews';
export default function AccountLayout(props) {
  const { match, location, address } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const role = user && user.role;

  const breadcrumb = [
    { title: 'Ana Sayfa', url: '' },
    { title: 'Hesabım', url: '' },
  ];

  const links = [
    { title: 'Kullanıcı Bilgileri', url: 'bilgiler' },
    { title: 'Siparişlerim', url: 'siparis' },
    { title: 'Değerlendirmelerim', url: 'degerlendirmelerim' },
    { title: 'Adres Bilgisi', url: 'adres' },
    { title: 'Şifre İşlemleri', url: 'sifre' },
  ].map((link, index) => {
    const url = `${match.url}/${link.url}`;
    const isActive = matchPath(location.pathname, { path: url, exact: true });
    const classes = classNames('account-nav__item', {
      'account-nav__item--active': isActive,
    });
    return (
      <li key={index} className={classes}>
        <Link to={url}>{link.title}</Link>
      </li>
    );
  });

  return (
    <>
      <PageHeader breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 d-flex align-items-start">
              <div className="account-nav flex-grow-1">
                <h4 className="account-nav__title">Hesap İşlemleri</h4>
                <ul>{links}</ul>
              </div>
            </div>
            <div className="col-12 col-lg-9 mt-4 mt-lg-0">
              <Switch>
                {user
                  ? <Redirect exact from={match.path} to={`${match.path}/bilgiler`} />
                  : <Redirect exact from={match.path} to={`${match.path}/login`} />}
                <Route exact path={`${match.path}/bilgiler`} component={AccountPageDashboard} />
                <Route exact path={`${match.path}/profil`} component={AccountPageProfile} />
                <Route exact path={`${match.path}/siparis`} component={AccountPageOrders} />
                <Route
                  exact
                  path={`${match.path}/siparis/:id`}
                  render={(props) => (
                    <AccountPageOrderDetails {...props} />)}
                />
                <Route
                  exact
                  path={`${match.path}/degerlendirmelerim`}
                  render={(props) => (
                    <AccountPageReviews {...props} />)}
                />

                <Route exact path={`${match.path}/adres`} component={AccountPageAddresses} />

                <Route exact path={`${match.path}/sifre`} component={AccountPagePassword} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
