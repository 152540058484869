// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import classNames from 'classnames';
import TelegramLoginButton from 'react-telegram-login';
import SimpleReactValidator from 'simple-react-validator';
import PageHeader from '../shared/PageHeader';
import { Cross12Svg, Quickview16Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import {
    login, loginWithFacebook, loginWithGoogle, loginWithTelegram,
} from '../../store/userAccount/userAccountActions';
// import FacebookLogin from 'react-facebook-login';
import config from '../../config.json';
import AsyncAction from '../shared/AsyncAction';

const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Giriş', url: '/hesap/cikis' },
];
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false),
    );
    return valid;
};

class AccountPageLogin extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: 'text-danger',
            messages: {
                required: ':attribute boş olamaz(!)',
                email: 'geçersiz :attribute adresi',
                min: ':attribute :min harften az olamaz(!)',
            },
        });
        this.state = {
            email: '',
            password: '',
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const formValid = this.validator.allValid();
        if (!formValid) {
            this.validator.showMessages();
            return;
        }
        this.props.login(this.state);
    }

    responseFacebook = (response) => {
        if (!response.accessToken) {
            console.error('Unable to get access token from Facebook', response);
            return;
        }

        this.props.loginWithFacebook(response.accessToken);
    }

    responseGoogle = (response) => {
        if (!response.tokenId) {
            console.error('Unable to get tokenId from Google', response);
            return;
        }
        this.props.loginWithGoogle(response.tokenId);
    };

    handleTelegramResponse = (response) => {
        const res = JSON.stringify(response);
        this.props.loginWithTelegram(res);
    };

    render() {
        const { email, password } = this.state;
        const { isLogin } = this.props;
        const mystyle = {
            color: 'red',
            padding: '5px',
            fontFamily: 'Arial',
            fontSize: 13,
        };
        const headerStyle = {
            marginBottom: 10,
            marginTop: 20,
        };
        const forgotPasswordStyle = {
            color: 'blue',
        };

        return (
            <>
                <Helmet>
                    <title>{`Giriş Yap — ${theme.name}`}</title>
                </Helmet>
                <div className="flex-grow-1 mb-0">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">E-posta</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={email}
                                onChange={this.handleChange}
                            />
                            {this.validator.message('eposta', email, 'required|email', { className: 'payment_credit_card_validation' })}
                        </div>

                        <div className="form-group">
                            <label htmlFor="register-password">Şifre</label>
                            <input
                                name="password"
                                type="password"
                                className="form-control"
                                onChange={this.handleChange}
                                value={password}
                            />
                            {this.validator.message('şifre', password, 'required|min:6', { className: 'payment_credit_card_validation' })}
                            <Link to="/hesap/sifre-yenile">
                                <span style={forgotPasswordStyle}>Şifremi Unuttum</span>
                            </Link>
                        </div>
                        <div>
                            {/* <AsyncAction
                                                    action={() => this.handleSubmit}
                                                    render={({ run, loading }) => (
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            title="Giriş Yap"
                                                            className={classNames('btn btn-primary btn-lg btn-block', {
                                                                'btn-loading': loading,
                                                            })}
                                                        >Giriş Yap
                                                        </button>
                                                    )}
                                                /> */}
                            <button
                                type="submit"
                                className={classNames('btn btn-primary btn-lg btn-block', {
                                    'btn-loading': isLogin,
                                })}
                            >
                                Giriş Yap
                </button>
                        </div>

                    </form>
                    <div className="orDivider">veya</div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <FacebookLogin
                                appId={config.FACEBOOK_APP_ID}
                                autoLoad={false}
                                fields="name,email,picture"
                                onClick={this.componentClicked}
                                callback={this.responseFacebook}
                                disableMobileRedirect
                                isMobile={false}
                                render={(renderProps) => (
                                    <button
                                        className="btnFacebook"
                                        onClick={renderProps.onClick}
                                    >
                                        <i className="fab fa-facebook-f" style={{ margin: '8px 10px 10px 0px' }} />
                      Facebook ile giriş
                                    </button>
                                )}
                            />

                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <GoogleLogin
                                clientId={config.GOOGLE_CLIENT_ID}
                                // buttonText="Google ile giriş"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy="single_host_origin"
                                render={(renderProps) => (
                                    <button
                                        className="btnGoogle"
                                        onClick={renderProps.onClick}
                                    >
                                        <i className="fab fa-google" style={{ margin: '8px 10px 10px 0px' }} />
                      Google ile giriş
                                    </button>
                                )}
                            />
                        </div>
                    </div>



                    <div className="row justify-content-center mt-3">
                        <div className="ml-a mr-a">
                            <TelegramLoginButton requestAccess dataOnauth={this.handleTelegramResponse} botName="bizlealBot" />
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLogin: state.userAccount.isLogin,
});
const mapDispatchToProps = {
    login,
    loginWithFacebook,
    loginWithGoogle,
    loginWithTelegram,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);
