import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';


import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './rootReducer'; // the value from combineReducers

const persistConfig = {
    key: 'bizleal',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

export const store = createStore(persistReducer(persistConfig, rootReducer), compose(applyMiddleware(thunk)));
export const persistor = persistStore(store);
