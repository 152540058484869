// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedRight6x9Svg } from '../../svg';
import Collapse from '../shared/Collapse';

function FilterCategoriesDeneme(props) {
  const { items, location, onItemClick } = props;
  let brandList;
  let arrow;

  const handleItemClick = (id, brands) => {
    if (onItemClick) onItemClick(id, brands);
  };

  const itemList = items.map((item) => {
    const searchPath = `/kategori?categoryId=${item.id}&`;
    const renderCategory = ({ toggle, setItemRef, setContentRef }) => {
      let expander;
      let children;
      if (item.subCategories && item.subCategories.length > 0) {
        expander = <button className="widget-categories__expander" type="button" aria-label="Expand" onClick={toggle} />;

        children = (
          <div className="widget-categories__subs" ref={setContentRef}>
            <ul>
              {item.subCategories.map((subcategory) => (
                <li key={subcategory.id}>
                  <Link
                    // to={`/kategori?categoryId=${item.id}+${subcategory.id}`}
                    to={`/kategori?categoryId=${item.id}`}
                    onClick={() => handleItemClick(subcategory.id, subcategory.brands)}
                  >
                    {subcategory.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      }

      return (

        <li className="widget-categories__item" ref={setItemRef}>
          <div className="widget-categories__row">

            <Link
              to={`/kategori?parentId=${item.id}`}
              onClick={() => handleItemClick(item.id, item.brands)}
            >

              <img className="category_list_icon" src={item.imgUrl} />
              {item.name}
            </Link>
            {expander}
          </div>
          {children}
        </li>
      );
    };
    return <Collapse key={item.id} toggleClass="widget-categories__item--open" render={renderCategory} />;
  });

  return (
    <div>
      <div className={`widget-categories widget-categories--location--${location} widget`} style={{ borderRadius: '10px' }}>
        <h4 className="widget__title">Kategoriler</h4>
        <ul className="widget-categories__list">
          {itemList}
        </ul>
      </div>
    </div>
  );
}

FilterCategoriesDeneme.propTypes = {
  items: PropTypes.array,
};

export default FilterCategoriesDeneme;
